.ItemInvoice {
  padding: 10px;
}
.ItemInvoice ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ItemInvoice ul li {
  /* flex: 1; */
  margin: 0;
  padding: 0;
}
.ItemInvoice ul li:nth-child(1) {
  width: 4%;
}
.ItemInvoice ul li:nth-child(2) {
  width: 15%;
}
.ItemInvoice ul li:nth-child(3) {
  width: 20%;
}
.ItemInvoice ul li:nth-child(4) {
  width: 20%;
}
.ItemInvoice ul li:nth-child(5) {
  width: 15%;
}
.ItemInvoice ul li:nth-child(6) {
  width: 15%;
}
.ItemInvoice ul li:nth-child(7) {
  width: 10%;
}
.ItemInvoice ul li {
  width: 10%;
}
.itemsinput ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.itemsinput ul li {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 20%;
  padding: 5px;
}
.itemsinput ul li:nth-child(1) {
  width: 4%;
}
.addinvocie input {
  border: 1px solid lightgrey;
}
.addinvocie {
  /* height: 80vh; */
  padding: 10px;
}
.itemreciepts {
  height: 35vh;
  border-bottom: 1px solid lightgrey;
}
.itemreciepts input {
  width: 100%;
  height: 35px;
  background-color: #f1faff;
  border: none;
}
 select {
  width: 100%;
  height: 35px;
  background-color: #f1faff;
  border: none;
}
.ItemInvoice {
  /* color: #0077b6; */
}
.dataobj label {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
}
.option span {
  padding: 10px;
  font-weight: bold;
}
.dataobj .col {
  border-right: 1px solid lightblue;
  border-bottom: 1px solid lightblue;
  border-top: 1px solid lightblue;
}
.pay {
  border-radius: 10px;
  background-color: #f1faff;
}
.searchbox {
  position: absolute;
  max-height: 200px;
  background-color: white;
  border: 1px solid rgb(189, 226, 246);
  width: 100%;
  padding: 10px;
  margin-top: 0px;
  border-radius: 10px;
  z-index: 100;
}
/* ------------------------------------------------------------------------------------------------------- */
.sales {
  position: relative;
}
.searchbox ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  /* height: 30px; */
}
.searchbox ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1;
  border-bottom: 1px solid lightgrey;
  padding: 4px;
  /* height: 30px; */
}
.searchbox button {
  font-size: 12px;
  width: 70%;
  margin-top: 5px;
  float: right;
}
.custview {
  position: relative;
  height: 100%;
  overflow: auto;
  max-height: 160px;
  /* height: 160px; */
}
/* --------------------------------------------------------------------------------------------------------------- */
hr {
  margin: 0;
  padding: 0;
  margin: 7px;
}
.selectarray {
  width: 90%;
  border-radius: 8px;
  padding-left: 8px;
  background-color: #d7ecf7;
  border: none;
}
.pay .col {
  padding: 10px;
}
.pay h4 {
  text-align: right;
  font-size: 16px;
}
.pay {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
}
.pay button {
  text-transform: uppercase;
  background-color: lightblue;
  border-radius: 6px;
}
