.LoginHeader {
  font-weight: bold;
}
.LoginHeader ion-icon {
  font-size: 25px;
  position: absolute;
  margin-left: -30px;
  margin-top: -5px;
}
.LoginHeader .row .col {
  padding: 10px;
}
.LoginHeader label {
  padding-left: 40px;
  padding-right: 40px;
}
.Login {
  width: 45%;
  /* border: 3px solid lightgrey; */
  margin: auto;
  margin-top: 20vh;
  border-radius: 10px;
  padding: 10px;
}
.Login p {
  padding: 0;
  margin: 0;
}
.Login .col:nth-child(1) input {
  width: 80%;
  padding: 5px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 20px;
}
.Login button {
  width: 80%;
}
.Captch {
  width: 80%;
  padding: 5px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 20px;
}
.Login button {
  width: 80%;
}
.Login h4 {
  text-align: center;
  font-weight: bold;
}
.rederror {
  background-color: rgb(242, 204, 204);
  color: rgb(105, 18, 18);
  padding: 10px;
  border-radius: 5px;
  font-weight: 400;
}
.rederror ion-icon {
  margin-right: 10px;
}
