.viewtap ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.viewtap ul li {
  flex: 1;
}
