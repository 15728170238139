@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
body {
  /* font-family: "Didact Gothic", sans-serif !important; */
  font-family: "Sen", sans-serif;
  font-family: "Niramit", sans-serif;
  background-color: #f1faff;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  font-family: "Didact Gothic", sans-serif !important;
  color: black;
}
.App {
  width: 100%;
  margin: auto;
}
.row {
  padding: 0;
  margin: 0;
}
.col {
  padding: 0;
  margin: 0;
}
.holdin {
  position: relative;
  /* margin-left: 160px; */
  padding-top: 5vh;
  width: 75%;
  margin: auto;
}
@font-face {
  font-family: myFirstFont;
  src: url(./Font/Nround\ thin.ttf);
  font-weight: bold;
}
* {
  /* font-family: myFirstFont; */
}
a {
  color: black;
  text-decoration: none;
}
h3 {
  font-size: 15px;
  font-weight: bold;
}
.addTabs label {
  font-weight: bold;
  padding: 10px;
  text-decoration: underline;
}
.addTabs input {
  display: block;
}
.addTabs input {
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 10px;
  width: 25%;
  border: 1px solid lightgrey;
}
.addTabsb label {
  font-weight: bold;
  padding: 10px;
  text-decoration: underline;
}
.addTabsb input {
  display: block;
}
.addTabsCust input {
  display: inline-block;
}
.addTabsCust input {
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 10px;
  width: 50%;
  border: 1px solid lightgrey;
}
.addTabsCust label {
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 10px;
  width: 30%;
  text-align: right;
}
.addTabsb input {
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 10px;
  width: 50%;
  border: 1px solid lightgrey;
}
input {
  border-radius: 8px;
  border: 1px solid lightgrey;
  
}
.dash input {
  width: 50%;
  padding: 8px;
  border: none;
}
