.Custheading {
  padding: 10px;
}
.Custheading ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 10px;
}
.Custheading ul li {
  /* flex: 1; */
  margin: 0;
  padding: 0;
}
.Custheading ul li:nth-child(1) {
  width: 5%;
}
.Custheading ul li:nth-child(2) {
  width: 25%;
}
.Custheading ul li:nth-child(3) {
  width: 30%;
}
.Custheading ul li:nth-child(4) {
  width: 16%;
}
.Custheading ul li:nth-child(5) {
  width: 16%;
}
.Custheading ul li:nth-child(6) {
  width: 7%;
}
.Custheading ion-icon {
  margin-left: 10px;
  font-size: 15px;
}
.CustlistTab {
  height: 60vh;
  overflow: auto;
}
