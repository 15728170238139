.sidebar {
  position: fixed;
  background-color: white;
  /* background: #0fa1e0; */
 
  /* margin-left: -160px; */
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-top: 5vh; */
  z-index: 10;
  height: 100vh;
  left: 0;
  overflow: hidden;
  width: 200px;
  padding-left: 20px;
}
.sidebar a {
  color: grey;
}
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px;
  padding-left: 15%;
}
.sidebar .smallText {
  /* border-bottom: 1px dotted #31b7f1; */
}
.sidebar .h_2 {
  font-weight: bold;
  margin-left: 10px;
}
.sidebar ion-icon {
  font-size: 20px;
  position: absolute;
  margin-left: -20px;
  margin-top: -15px;
  font-weight: bold;
}
 
 .smallText label:hover{
  cursor: pointer;
}
.datamenu{
  margin-left: 10px;
}