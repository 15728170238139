.dash {
  padding: 10px;
  background-color: #169597;
  color: white;
  text-align: center;
  /* border-left: 2px solid #0fa1e0;
  border-bottom: 2px solid #0fa1e0; */
}
.itemadd .row .col:nth-child(2) {
  border-left: 1px solid lightgrey;
}
.itemadd button {
  font-size: 13px;
  margin: 10px;
  width: 20%;
}
.Itemheading {
  padding: 10px;
}
.Itemheading ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.Itemheading ul li {
  flex: 1;
  margin: 0;
  padding: 8px;
}
.Itemheading ion-icon {
  margin-left: 10px;
  font-size: 15px;
}
.itemlistTab {
  height: 56vh;
  overflow-x: auto;
}
.itemadd input {
  text-align: center;
  text-transform: capitalize;
}
